(function ($) {
  Drupal.behaviors.gnavCustomV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-gnav-custom-v1', context);
      var $imgPattern = $template.find('img');
      var $body = $('body');
      var gnavStyle = $template.data('gnav-custom-style');
      var isTransparent = $template.data('gnav-transparent-toggle');
      var logoExpand = $template.data('gnav-logo-expand');
      var navigationHeight = $template.data('gnav-height');
      var pageBackgroundCustom = $template.data('body-custom');
      var isBackgroundCustom = (pageBackgroundCustom !== undefined) ? 1 : 0;
      var bgCustomMono = (pageBackgroundCustom === 'bg-custom--mono') ? true : false;
      var bgCustomGradient = (pageBackgroundCustom === 'bg-custom--gradient') ? true : false;
      var bgMonoColor = $template.data('bg-mono-color');
      var bgGradAngle = $template.data('bg-gradient-color');
      var bgGradColorStart = $template.data('gradient-color-st');
      var bgGradColorEnd = $template.data('gradient-color-nd');
      var bgImgPatternUrl = $imgPattern.prop('src');
      var isImgUsed = (bgImgPatternUrl !== undefined) ? 1 : 0;
      var toDirection = (bgGradAngle === 'bg-custom--v') ? 'to bottom'
        : (bgGradAngle === 'bg-custom--h') ? 'to right'
        : (bgGradAngle === 'bg-custom--d-up_down') ? 'to bottom right'
        : (bgGradAngle === 'bg-custom--d-down_up') ? 'to top right'
        : false;

      if (gnavStyle.length) {
        if (isBackgroundCustom > 0) {
          $body.addClass('bg-custom ' + pageBackgroundCustom);
          if (isImgUsed > 0) {
            $body.addClass('bg-custom--pattern');
          }
          if (bgCustomMono > 0) {
            $body.css('background-color', bgMonoColor);
            if ($body.hasClass('bg-custom--pattern') && bgImgPatternUrl) {
              $body.css('background-image', 'url(' + bgImgPatternUrl + ')');
            }
          }
          if (bgCustomGradient > 0) {
            var bgImgStyle = bgImgPatternUrl ? 'url(' + bgImgPatternUrl + '),' : '';
            $body.css('background-image', bgImgStyle + 'linear-gradient('+ toDirection + ',' + bgGradColorStart + ' 30% ,' + bgGradColorEnd + ')');
            $body.css('background-image', bgImgStyle + '-webkit-linear-gradient('+ toDirection + ',' + bgGradColorStart + '30% ,' + bgGradColorEnd + ')');
            $body.css('background-image', bgImgStyle + '-moz-linear-gradient('+ toDirection + ',' + bgGradColorStart + '30% ,' + bgGradColorEnd + ')');
            $body.css('background-image', bgImgStyle + '-ms-linear-gradient('+ toDirection + ',' + bgGradColorStart + '30% ,' + bgGradColorEnd + ')');
          }
        }
      }
    }
  };
})(jQuery);
